import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Hero from '../components/Hero/Hero'
import bgImage from '../images/curves.svg'

class ThanksPage extends React.Component {
  render() {
    return (
      <Layout color="dark">
        <SEO title="Thanks" />
        <section className="section section--gradient">
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <section className="section has-text-centered">
                  <h2 className="subtitle is-size-3">Thanks!</h2>
                  <div className="content is-size-5">
                    <p>
                      Thank you for getting in touch, we have received your
                      message and will contact you shortly.
                    </p>
                    <p>
                      In the meantime, why not take a look at our
                      {' '}
                      <Link to="/projects">portfolio</Link>
?
                    </p>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default ThanksPage
